import React, { useContext, useState } from "react";
import { AppContext } from "../context";
import { useUIText } from "../hooks";
import { formatDeviceName } from "../utils";
import ReactSelect from "react-select";
import { SubmitButton } from "./NewFlowConversationContainer";
import AlertTriangle from "../assets/alert-triangle.svg";
import ShowIf from "./ShowIf";

export default function DistributorSelector({
  selectedDevice,
  handleSubmittingDevice,
  setSelectedDevice,
  disabled,
  classification,
  submitDisabled = false,
  isChatbot = false,
}) {
  const { instanceInfo } = useContext(AppContext);
  const [selectedType, setSelectedType] = useState(null);
  const [selectedManufacturer, setSelectedManufacturer] = useState(null);
  const uiText = useUIText();
  React.useEffect(() => {
    if (classification) {
      const deviceType = instanceInfo?.deviceTypes?.find((type) => {
        const manufacturer = type?.manufacturers?.find((manufacturer) => {
          return manufacturer?.manufacturer === classification?.brand;
        });
        return (
          manufacturer &&
          (!classification?.device ||
            manufacturer?.devices?.find((device) => {
              return (
                formatDeviceName(device.deviceName) === classification?.device
              );
            }))
        );
      });
      if (deviceType) {
        setSelectedType({
          value: deviceType.type,
          label:
            uiText?.deviceTypes?.[deviceType?.type?.toLowerCase()] ||
            deviceType?.translatedType ||
            deviceType?.type,
        });
        const manufacturerInfo = deviceType?.manufacturers?.find(
          (manufacturer) => {
            return manufacturer?.manufacturer === classification?.brand;
          }
        );
        if (manufacturerInfo) {
          setSelectedManufacturer({
            value: manufacturerInfo.companyName,
            label: manufacturerInfo.manufacturer,
            icon: manufacturerInfo.icon,
          });
          const deviceInfo = manufacturerInfo?.devices?.find((device) => {
            return (
              formatDeviceName(device.deviceName) === classification?.device
            );
          });
          if (deviceInfo) {
            setSelectedDevice({
              deviceName: deviceInfo.deviceName,
              manufacturer: manufacturerInfo.companyName,
              deviceType: deviceType.type,
            });
          }
        }
      }
    }
  }, [
    classification,
    instanceInfo,
    setSelectedType,
    uiText,
    setSelectedDevice,
  ]);

  const types = React.useMemo(() => {
    return instanceInfo?.deviceTypes?.map((type) => {
      return {
        value: type.type,
        label:
          uiText?.deviceTypes?.[type?.type?.toLowerCase()] ||
          type?.translatedType ||
          type?.type,
      };
    });
  }, [instanceInfo.deviceTypes, uiText]);
  const manufacturers = React.useMemo(() => {
    if (!selectedType) return [];
    return instanceInfo?.deviceTypes
      ?.find((item) => item.type === selectedType.value)
      ?.manufacturers.map((manufacturer) => {
        return {
          value: manufacturer.companyName,
          label: manufacturer.manufacturer,
          icon: manufacturer.icon,
        };
      });
  }, [selectedType, instanceInfo.deviceTypes]);
  const devices = React.useMemo(() => {
    if (!selectedType || !selectedManufacturer) return [];
    return (
      instanceInfo?.deviceTypes
        ?.find((item) => item.type === selectedType.value)
        ?.manufacturers.find(
          (item) => item.companyName === selectedManufacturer.value
        )
        ?.devices.map((device) => {
          return {
            value: device.deviceName,
            label: formatDeviceName(device.deviceName),
          };
        }) ?? []
    );
  }, [selectedType, selectedManufacturer, instanceInfo.deviceTypes]);
  const selectedDeviceValue = React.useMemo(() => {
    if (!devices?.length) return null;
    return (
      devices.find((device) => device.value === selectedDevice?.deviceName) ??
      null
    );
  }, [selectedDevice, devices]);
  return (
    <div
      className={`selection-wrapper`}
      style={{ pointerEvents: disabled ? "none" : "auto", gap: "1rem", padding: "14px 0 16px" }}
    >
      <div className="new-selection-container" >
        <div className="customer-selection" style={{gap: "8px"}}>
          <ReactSelect
            className="basic-single"
            classNamePrefix="select"
            isClearable
            isSearchable
            options={types}
            placeholder={uiText?.form?.deviceTypePlaceholder}
            value={selectedType}
            onChange={(value) => {
              setSelectedType(value);
              setSelectedDevice(null);
            }}
            menuPlacement="top"
          />
          <ReactSelect
            className="basic-single"
            classNamePrefix="select"
            isClearable
            isSearchable
            options={manufacturers}
            placeholder={
              manufacturers.length === 0
                ? uiText?.form?.manufacturerPlaceholder1
                : uiText?.form?.manufacturerPlaceholder2
            }
            value={selectedManufacturer}
            onChange={(value) => {
              setSelectedManufacturer(value);
              setSelectedDevice(null);
            }}
            isDisabled={manufacturers.length === 0}
            menuPlacement="top"
            formatOptionLabel={({ label, icon }) => (
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ width: "60px", textAlign: "center" }}>
                  {icon ? (
                    <img
                      src={icon}
                      alt={label}
                      style={{
                        maxWidth: "50px",
                        height: "auto",
                        maxHeight: "14px",
                        marginRight: 10,
                      }}
                    />
                  ) : null}
                </div>
                {label}
              </div>
            )}
          />
          <ReactSelect
            className="basic-single"
            classNamePrefix="select"
            isClearable
            isSearchable
            options={devices}
            placeholder={
              devices.length === 0
                ? uiText?.form?.deviceModelPlaceholder1
                : uiText?.form?.deviceModelPlaceholder2
            }
            value={selectedDeviceValue}
            onChange={(value) => {
              setSelectedDevice({
                deviceName: value.value,
                manufacturer: selectedManufacturer.value,
                deviceType: selectedType.value,
              });
            }}
            isDisabled={devices.length === 0}
            menuPlacement="top"
          />
        </div>
        <SubmitButton
          disabled={submitDisabled}
          onClick={() => {
            handleSubmittingDevice(selectedDevice);
          }}
        />
      </div>
      {/* <ShowIf condition={!selectedDevice}>
        <div className="device-select-warning">
          <img
            alt="alert"
            src={AlertTriangle}
            width={"15.26px"}
            height={"13.4px"}
          />
          <span>{uiText?.form?.selectDeviceWarning}</span>
        </div>
      </ShowIf> */}
		</div>
	);
}
